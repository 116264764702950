import * as React from 'react';
import { styled } from '@mui/material/styles';
import { Grid, Card, CardActions, CardContent, CardMedia, Typography, } from '@material-ui/core';
import './aboutus-our-shining.scss';
import AboutJewelsThumb1 from 'assets/aboutjewels-thumb1.png';
import AboutJewelsThumb2 from 'assets/aboutjewels-thumb2.png';
import AboutJewelsThumb3 from 'assets/aboutjewels-thumb3.png';
import AboutJewelsThumb4 from 'assets/aboutjewels-thumb4.png';
import AboutJewelsThumb5 from 'assets/aboutjewels-thumb5.png';
import AboutJewelsThumb6 from 'assets/aboutjewels-thumb6.png';
import AboutJewelsThumb7 from 'assets/aboutjewels-thumb7.png';
import Container from '@material-ui/core/Container';

export default function AboutusOurShining() {

    return (
        <>
            <Container maxWidth="xl" className='about-ourshining-cls'>
                <Grid container className="about-ourshining">
                    <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                        <Grid item xl={3} lg={4} md={12} sm={12} xs={12}>
                            <h2 className='title'>our shining jewels</h2>
                        </Grid>
                        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                            <h3 className='sub-title'>we care to produce the best for you, always!</h3>
                        </Grid>
                    </Grid>
                    {/* <Grid item container xl={12} lg={12} md={12} sm={12} xs={12} className="capsules-bg"></Grid> */}
                    <Grid container spacing={2}>
                        <Grid item xl={3} lg={3} md={4} sm={5} xs={6}>
                            <Card className="thumb-card">
                                <img src={AboutJewelsThumb1} alt="Thumb Image" />
                                <CardContent className="descp">
                                    <Typography gutterBottom variant="h5" component="h4">Carla Mendleway</Typography>
                                    <Typography variant="body2" component="p">Senior Executive, IT</Typography>
                                    <Typography variant="body2" component="p">EMEA</Typography>
                                    <Typography variant="body2" component="p">APAC</Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item xl={3} lg={3} md={4} sm={5} xs={6}>
                            <Card className="thumb-card">
                                <img src={AboutJewelsThumb2} alt="Thumb Image" />
                                <CardContent className="descp">
                                    <Typography gutterBottom variant="h5" component="h4">Glenda Sheffield-Adelie Haffeueser</Typography>
                                    <Typography variant="body2" component="p">Executive Senior Vice President</Typography>
                                    <Typography variant="body2" component="p">Global Talent Mobility,</Typography>
                                    <Typography variant="body2" component="p">EMEA & APAC</Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item xl={3} lg={3} md={4} sm={2} xs={6}>&nbsp;</Grid>
                        <Grid item xl={3} lg={3} md={4} sm={5} xs={6}>
                            <Card className="thumb-card">
                                <img src={AboutJewelsThumb3} alt="Thumb Image" />
                                <CardContent className="descp">
                                    <Typography gutterBottom variant="h5" component="h4">Sophia Johnson</Typography>
                                    <Typography variant="body2" component="p">Senior Executive, IT</Typography>
                                    <Typography variant="body2" component="p">EMEA</Typography>
                                    <Typography variant="body2" component="p">APAC</Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item xl={3} lg={3} md={4} sm={5} xs={6}>
                            <Card className="thumb-card">
                                <img src={AboutJewelsThumb4} alt="Thumb Image" />
                                <CardContent className="descp">
                                    <Typography gutterBottom variant="h5" component="h4">Rob Gronkowski</Typography>
                                    <Typography variant="body2" component="p">Senior Executive, IT</Typography>
                                    <Typography variant="body2" component="p">EMEA</Typography>
                                    <Typography variant="body2" component="p">APAC</Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item xl={3} lg={3} md={4} sm={2} xs={6}>&nbsp;</Grid>
                        <Grid item xl={3} lg={3} md={4} sm={5} xs={6}>
                            <Card className="thumb-card">
                                <img src={AboutJewelsThumb5} alt="Thumb Image" />
                                <CardContent className="descp">
                                    <Typography gutterBottom variant="h5" component="h4">Lavenelle Simone</Typography>
                                    <Typography variant="body2" component="p">Senior Executive, IT</Typography>
                                    <Typography variant="body2" component="p">EMEA</Typography>
                                    <Typography variant="body2" component="p">APAC</Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item xl={3} lg={3} md={4} sm={5} xs={6}>
                            <Card className="thumb-card">
                                <img src={AboutJewelsThumb6} alt="Thumb Image" />
                                <CardContent className="descp">
                                    <Typography gutterBottom variant="h5" component="h4">Ben Harper</Typography>
                                    <Typography variant="body2" component="p">Senior Executive, IT</Typography>
                                    <Typography variant="body2" component="p">EMEA</Typography>
                                    <Typography variant="body2" component="p">APAC</Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item xl={3} lg={3} md={4} sm={2} xs={6}>&nbsp;</Grid>
                        <Grid item xl={3} lg={3} md={4} sm={5} xs={6}>
                            <Card className="thumb-card">
                                <img src={AboutJewelsThumb7} alt="Thumb Image" />
                                <CardContent className="descp">
                                    <Typography gutterBottom variant="h5" component="h4">Glen Symonds</Typography>
                                    <Typography variant="body2" component="p">Senior Executive, IT</Typography>
                                    <Typography variant="body2" component="p">EMEA</Typography>
                                    <Typography variant="body2" component="p">APAC</Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item xl={3} lg={3} md={4} sm={5} xs={6}>&nbsp;</Grid>
                        <Grid item xl={3} lg={3} md={4} sm={5} xs={6}>
                            <Card className="thumb-card">
                                <img src={AboutJewelsThumb4} alt="Thumb Image" />
                                <CardContent className="descp">
                                    <Typography gutterBottom variant="h5" component="h4">Rob Gronkowski</Typography>
                                    <Typography variant="body2" component="p">Senior Executive, IT</Typography>
                                    <Typography variant="body2" component="p">EMEA</Typography>
                                    <Typography variant="body2" component="p">APAC</Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item xl={3} lg={3} md={4} sm={5} xs={6}>
                            <Card className="thumb-card">
                                <img src={AboutJewelsThumb1} alt="Thumb Image" />
                                <CardContent className="descp">
                                    <Typography gutterBottom variant="h5" component="h4">Carla Mendleway</Typography>
                                    <Typography variant="body2" component="p">Senior Executive, IT</Typography>
                                    <Typography variant="body2" component="p">EMEA</Typography>
                                    <Typography variant="body2" component="p">APAC</Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item xl={3} lg={3} md={4} sm={5} xs={6}>
                            <Card className="thumb-card">
                                <img src={AboutJewelsThumb2} alt="Thumb Image" />
                                <CardContent className="descp">
                                    <Typography gutterBottom variant="h5" component="h4">Glenda Sheffield-Adelie Haffeueser</Typography>
                                    <Typography variant="body2" component="p">Executive Senior Vice President</Typography>
                                    <Typography variant="body2" component="p">Global Talent Mobility,</Typography>
                                    <Typography variant="body2" component="p">EMEA & APAC</Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item xl={3} lg={3} md={4} sm={7} xs={6}>&nbsp;</Grid>
                        <Grid item xl={3} lg={3} md={4} sm={5} xs={6}>
                            <Card className="thumb-card">
                                <img src={AboutJewelsThumb3} alt="Thumb Image" />
                                <CardContent className="descp">
                                    <Typography gutterBottom variant="h5" component="h4">Sophia Johnson</Typography>
                                    <Typography variant="body2" component="p">Senior Executive, IT</Typography>
                                    <Typography variant="body2" component="p">EMEA</Typography>
                                    <Typography variant="body2" component="p">APAC</Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item xl={6} lg={6} md={8} sm={7} xs={6}>&nbsp;</Grid>
                        <Grid item xl={3} lg={3} md={4} sm={5} xs={6}>
                            <Card className="thumb-card">
                                <img src={AboutJewelsThumb7} alt="Thumb Image" />
                                <CardContent className="descp">
                                    <Typography gutterBottom variant="h5" component="h4">Lavenelle Simone</Typography>
                                    <Typography variant="body2" component="p">Senior Executive, IT</Typography>
                                    <Typography variant="body2" component="p">EMEA</Typography>
                                    <Typography variant="body2" component="p">APAC</Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                </Grid>
            </Container>
        </>
    )
}


