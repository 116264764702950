import React, { useState } from 'react';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import CloseIcon from "@material-ui/icons/Close";
import { Button, Dialog, DialogContent, Slide, IconButton, Box } from '@material-ui/core';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import aboutus_heroimg from '../../../assets/aboutus-Heroimg.png';
import our_solutions_img from '../../../assets/aboutus-Videoimg.png';
import './aboutus-header.scss';
import Container from '@material-ui/core/Container';
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});
export default function Aboutusheader() {
  const [open, setOpen] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <>
    <div className='aboutus-hero'>
    <div className='aboutus-header-cls'>
      {/* <grid for image background> */}
      <Grid className='header-div'>
        <Grid container className='header-container'>
          <Grid item xs={12} sm={12} md={12} lg={10} xl={10} className='header-hero-bg'>   
          {/* <img src={aboutus_heroimg} alt="About us"></img>          */}
            <div className='blue-round-div'>
              <h1 className='title'>about us</h1>
              <p className='blue-sec-desp'>whether you’re designing your first mobility program or relocating thousands of executives people each year, cartus delivers proactive dynamic, customized flexible solutions that meet your unique needs, everytime.</p>
            </div>
          </Grid>
        </Grid>
      </Grid>
    </div>
    </div>
    <Container maxWidth="xl" className='aboutus-header-cls'>
      {/* <about us solutions section> */} 
      <Grid container className='abtus-txt-with-video-container'>
        <Grid container item xs={12} sm={12} md={12} lg={12} xl={12} className='header-intro-div'>
        <Grid item xs={6} sm={5} md={5} lg={7} xl={6}>
          <h2 className='title'>live better. work smarter. contribute more.</h2>
        </Grid>
        <Grid container item xs={12} sm={12} md={12} lg={12} xl={12}>
        <Grid item xs={12} sm={12} md={12} lg={6} xl={5}>
          <h3 className='sub-title'>enter exceptional services arena.</h3>
          <p className='description'>For more than 60 years, Cartus has helped relocating employees and
            their families find their way to new homes, new communities, and
            new experiences. We do it through an unwavering focus on listening
            and delivering services, solutions, and expertise that respond to
            their needs and the needs of their companies around the world.
            Whatever the move or location, we couple personal attention with
            the expertise gained through more than 4 million moves and a
            diverse client base.</p>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={6} xl={7}>
          <div className='image-box'>
            <Paper elevation={0} className="video-content">
              <Button className="button-container" onClick={handleClickOpen}>
                <img src={our_solutions_img}
                  alt="video"
                  className="video-img" />
                <PlayCircleOutlineIcon fontSize="large" className="play-button-icon" />
              </Button>
              <Dialog open={open} TransitionComponent={Transition} onClose={handleClose} maxWidth="md">
                <Box className="popup-overlay">
                  <IconButton className="popup-close-icon" onClick={handleClose}>
                    <CloseIcon />
                  </IconButton>
                  <DialogContent >
                    <Grid container className='dialog-content'>
                      <div className="iframe-class">
                        <iframe
                          width="560"
                          height="349"
                          className="responsive-iframe"
                          src="https://cartus.qumucloud.com/view/LAPdobvOgaG?autoPlay=true&embed=true&host=widgets&iframeName=kv-video-player-1-iframe-9247717&autoscale=true&AppProperties.PlayerShowEmbedButton=false" />
                      </div>
                    </Grid>
                  </DialogContent>
                </Box>
              </Dialog>
              <Grid>
                <Paper elevation={0}>
                  <Typography className="video-title natom-medium">our solutions</Typography>
                  <Typography className="video-subtitle">how cartus helped magnolia sheia</Typography>
                </Paper>
              </Grid>
            </Paper>
          </div>
        </Grid>
        </Grid>
        </Grid>
      </Grid>
    </Container>

   
    </>
  )
}