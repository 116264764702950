import React from 'react'
import './aboutus-our-quote.scss';
import { Grid, Typography, Box, Card, CardActionArea, CardContent, CardMedia } from '@material-ui/core';
import Carousel from 'react-elastic-carousel';
import ImageOne from '../../../assets/Testimonial.png';
import Container from '@material-ui/core/Container';

export default function AboutusOurQuote() {

  // breakpoints
  const breakPoints = [
    { width: 1, itemsToShow: 1 },
    { width: 599, itemsToShow: 1 },
    { width: 904, itemsToShow: 1 },
    { width: 1239, itemsToShow: 1 }
  ];

  const state = {
    items: [
      {
        id: 1,
        image: ImageOne,
        bookmark: 'KRISHA JOFFIELD',
        title: "People’s Officer, John Mayer Inds.",
        description: "Maecenas vestibulum felis in lacus pharetra, eget laoreet purus lobortis. Sed eget diam eu urna hendrerit euismod eu et nulla. Etiam consectetur maximus porta. scelerisque. Curabitur quam nulla, bibendum eu felis ac, porttitor blandit risused insignia magna."
      },

      {
        id: 2,
        image: ImageOne,
        bookmark: 'FEATURED',
        title: "Out Now!",
        description: " simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book"
      },
      {
        id: 3,
        image: ImageOne,
        bookmark: 'Various versions',
        title: "Many web sites",
        description: "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here"
      },
    ]
  }
  const { items } = state;
  return (
    <Container maxWidth="xl" className='aboutus-our-quote-div'>
    <div>
      <Grid container>
        <Grid item container xl={12} lg={12} md={12} sm={12} xs={12}>
          <Grid item container xl={12} lg={12} md={12} sm={12} xs={12} className="carousel-main-about-cls">
            <Carousel breakPoints={breakPoints}>
              {
                items.map((item, i) => <Item key={i} item={item} />)
              }
            </Carousel>
          </Grid>
        </Grid>
      </Grid>
    </div>
    </Container>
  )

  function Item(props) {
    return (
      <>
        <Card className='aboutus-bg-cls'>
          <Grid item container xl={12} lg={12} md={12} sm={12} xs={12} className='slide-row'>
            <Grid item xl={5} lg={5} md={5} sm={12} xs={12} >
              <CardMedia component="img"
                image={props.item.image} className='img-section'
              />
            </Grid>
            <Grid item xl={7} lg={7} md={7} sm={12} xs={12} className='testimonial-info' >
              <CardContent className="author-details">
              <div className="descp-sec">  
                <Typography className='quote-icon'> {''} </Typography>
                <Typography className='testimonial-desp'> {props.item.description}</Typography>
                </div>
                <div className="name-sec">               
                  <Typography className='name'> {props.item.bookmark} </Typography>
                  <Typography className='designation'> {props.item.title} </Typography>
                </div>

              </CardContent>
            </Grid>
          </Grid>
        </Card>
      </>
    )

  }

}


