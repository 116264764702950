import React from 'react'
import './aboutus-press.scss'
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Container from '@material-ui/core/Container';

export default function AboutusPress() {

    return (
        <>
         <Container maxWidth="xl" className='about-us-press-cls'>
            <Grid className='about-us-press-div'>
                <h2 className="title-press-release natom-medium">press releases</h2>
                <Grid container spacing={2} className="secure-container-about" sx={{ backgroundColor: "#FFFFFF" }} >
                    <Grid item xl={5} lg={5} md={5} sm={12} xs={12}>
                        <h2 className='date-year'>JUNE 15, 2022</h2>
                        <h4 className="title-press-about natom-medium">cartus accelerates strategic growth plans with major client signings and expansions; celebrates top rankings in respected industry survey</h4>
                        <p className='para-title natom-regular'>Cartus places #1 in Average Score among large providers in 6 out of 7 categories in long-running Relocation Managers’ Survey</p>
                        <div>
                            <Button variant="outlined" className='btn-press-room'>view press room </Button>
                        </div>
                    </Grid>
                    <Grid item xl={2} lg={2} md={2}>&nbsp;</Grid>
                    <Grid item xl={5} lg={5} md={5} sm={12} xs={12}>
                        <h2 className='date-year'>MARCH 30, 2022</h2>
                        <div className="title-press-about natom-medium">cartus declares the future of mobility is here, and it’s flexible!</div>
                        <p className='para-title natom-regular'>Long-time relocation industry leader announces strategic focus on designing and delivering flexible mobility programs to support current demographic trends among clients and their relocating employees</p>

                    </Grid>
                </Grid>
                </Grid>
                </Container>
        </>
    )
}


