import React from 'react'
import './aboutus-achievement.scss'
import aboutThumb1 from 'assets/achievement-1.png';
import aboutThumb2 from 'assets/achievement-2.png';
import aboutThumb3 from 'assets/achievement-3.png';
import aboutThumb4 from 'assets/achievement-4.png';
import aboutThumb5 from 'assets/achievement-5.png';
import aboutThumb6 from 'assets/achievement-6.png';
import Grid from '@material-ui/core/Grid';
import plus_image from '../../../assets/plus_get_touch.png';
import image_gettouch from '../../../assets/image_get_touch.png';
import Button from '@mui/material/Button';
import Container from '@material-ui/core/Container';
import ImageSlider from '../../../staticcomponents/image-slider/index';

export default function AboutusAchievement() {
  const ImgArray= [aboutThumb1,aboutThumb2,aboutThumb3,aboutThumb4,aboutThumb5]
  return (
    <>
      <Container maxWidth="xl" className='about-us-joinus-container'>
        <Grid item container xl={12} lg={12} md={12} sm={12} xs={12} className="our-about-cls">
        <ImageSlider ImageArray={ImgArray}/>
        </Grid>
      </Container>
      <Grid item container xl={12} lg={12} md={12} sm={12} xs={12} className="join-us-dot-matrix"></Grid>
      <div className='about-us-main-sec'>
      <Container maxWidth="xl" className='about-us-joinus-container'  >
        <Grid className='aboutus-join-cls'>
          <Grid container className="secure-container-about" >
            <Grid item xl={6} lg={6} md={6} sm={12} xs={12} >
              <img className="plus-image" src={plus_image} />
              <h2 className="title-get-touch">join our team</h2>
              <h3 className="sub-title-get-touch">I want to join the Cartus team</h3>
              <p>If you are talented, curious, and interested in building the future of global talent mobility with an industry leader, please apply to join our team!</p>
              <div>
                <Button variant="outlined" className='btn-join-us'>Join us</Button>
              </div>
            </Grid>
            <Grid item xl={6} lg={6} md={6} sm={12} xs={12} className="right-image-div">
              <img className="image-gettouch" src={image_gettouch} />

            </Grid>

          </Grid>
        </Grid>
      </Container>
      </div>
    </>
  )
}


