import React from 'react'
import Carousel from 'react-elastic-carousel';
import { Grid, Box, Button } from "@mui/material";
import './aboutus-our-rich.scss'
import expertise_icon from '../../../assets/unrelenting-icon.png';
import Container from '@material-ui/core/Container';

export default function AboutusOurRich() {

    // breakpoints
    const breakPoints = [
        { width: 1, itemsToShow: 2 }, // works for 428
        { width: 428, itemsToShow: 2 }, // works for 428
        { width: 500, itemsToShow: 2 }, // works for 600
        { width: 700, itemsToShow: 3 }, // works for 905
        { width: 768, itemsToShow: 3 }, // works for 1240
        { width: 992, itemsToShow: 5 }, // works for 1536 and above
        { width: 1100, itemsToShow: 6 }
    ];

    const state = {
        items: [
            {
                id: 1,
                date: 'May, 2022',
                paragraph: "Maecenas vestibulum felis in lacus pharetra, eget laoreet purus lobortis. Sed eget diam eu urna hendrerit euismod eu et nulla. Etiam consectetur maximus porta."
            },
            {
                id: 2,
                date: 'February, 2020',
                paragraph: "Curabir Orem felis in lacus pharetra, eget laoreet purus lobortis. Sed eget diam eu urna hendrerit euismod eu et nulla. Etiam consectetur sed magna."
            },
            {
                id: 3,
                date: 'September, 2018',
                paragraph: "Suspendisse consectetur venenatis neque, quis vehicula augue pretium id. Sed finibus pretium tortor sagittis placerat."
            },
            {
                id: 4,
                date: 'April, 2016',
                paragraph: "Quisque fermentum nisl vitae quam rhoncus, quis dapibus quam tincidunt. Phasellus dictum rhoncus neque."
            },
            {
                id: 5,
                date: 'April, 2016',
                paragraph: "Quisque fermentum nisl vitae quam rhoncus, quis dapibus quam tincidunt. Phasellus dictum rhoncus neque."
            },
            {
                id: 6,
                date: 'April, 2016',
                paragraph: "Quisque fermentum nisl vitae quam rhoncus, quis dapibus quam tincidunt. Phasellus dictum rhoncus neque."
            },
            {
                id: 7,
                date: 'April, 2016',
                paragraph: "Quisque fermentum nisl vitae quam rhoncus, quis dapibus quam tincidunt. Phasellus dictum rhoncus neque."
            }
        ]
    }

    const { items } = state;
    return (
            <Container maxWidth="xl" className='aboutus-our-rich-cls'>
                <Grid className="aboutus-our-rich">
                    <Grid container>
                        {/* <Grid item xl={1} lg={1} md={2} sm={0} xs={0}></Grid> */}
                        <Grid item container xl={2} lg={2} md={10} sm={12} xs={12} className='our-rich-heading'>
                            {/* <Grid item xl={0} lg={0} md={2} sm={0} xs={0}></Grid> */}
                            {/* <Grid item xl={12} lg={12} md={12} sm={12} xs={12}> */}
                            <div className='main-heading'>
                                <img src={expertise_icon} alt="expertise icon" className='our-rich-arrow' />
                                <h3 className='our-rich-title'>our rich
                                    heritage</h3>
                            </div>
                        </Grid>
                        {/* </Grid> */}
                        <Grid item container xl={10} lg={10} md={12} sm={12} xs={12} className='our-rich-columns'>
                            {/* <Grid item xl={0} lg={0} md={2} sm={0} xs={0}></Grid> */}
                            <Grid item xl={12} lg={12} md={12} sm={12} xs={12} className="our-rich-block">
                                <Box>
                                    <div className='our-rich-box'>
                                        <Carousel breakPoints={breakPoints} itemPadding={[0, 10]} >
                                            {
                                                items.map((item, i) => <Item key={i} item={item} />)
                                            }
                                        </Carousel>
                                    </div>
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid >
                </Grid >
            </Container>
    )

    function Item(props) {
        console.log("data", props)
        return (
            <>
                <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                    <div className='our-rich-column'>
                        <p className='our-rich-date'>{props.item.date}</p>
                        <p className='our-rich-para'>{props.item.paragraph}</p>
                    </div>
                </Grid>
            </>
        )
    }
}


