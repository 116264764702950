import React from 'react'
import Layout from '../../staticcomponents/layout'
import Aboutusheader from './aboutus-header'
import AboutusOurQuote from './aboutus-our-quote-logo'
import AboutusAchievement from './aboutus-achievement';
import AboutusOurRich from './aboutus-our-rich'
import AboutusOurShining from './aboutus-our-shining'
import AboutusOurWinning from './aboutus-our-winning'
import AboutusPress from './aboutus-press'
import './aboutus.scss'


export default function ContactUs() {
    return (
        <>
            <Layout>
                <div className='aboutus-main'>
                    <Aboutusheader />                                               
                    <div className='bg-donuts-left'>                    
                        <AboutusOurRich />                        
                        <AboutusOurWinning />
                    </div>
                    <div className='bg-donuts-right'>
                    <div className='capsules-bg'>
                        <AboutusOurShining />
                        </div>
                        <AboutusOurQuote />
                    </div>  
                    <AboutusAchievement />
                    <AboutusPress />
                </div>
            </Layout>
        </>
    )
}